/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Show,
  Container,
  SkeletonCircle,
  useColorMode,
  Text,
  Divider
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NextLink from 'next/link';
import { SearchComponent } from '../Search';
import { AiOutlineUser } from 'react-icons/ai';
import { BsMoonFill, BsSun } from 'react-icons/bs';
import { ChevronDownIcon } from '@chakra-ui/icons'
import NavbarProfileMenu from './NavbarProfileMenu';
import Router from "next/router";
import { useRouter } from 'next/router'
import {
  FaDiscord
} from 'react-icons/fa';

const NAV_ITEMS = [
  {
    label: 'Home',
    href: '/',
    key: "home-key"
  },
  {
    label: 'About',
    href: '/about',
    key: "about-key"
  },
  /*{
    label: 'Blacklist',
    href: '/blacklist',
    key: "blacklist-key"
  },
  {
    label: 'Stats',
    href: '/stats',
    key: "stats-key"
  },*/
  {
    label: 'More',
    children: [
      {
        label: 'Discord Bot',
        href: '/invite',
        key: "bot-key",
        external: true,
        dividerBelow: true
      },
      {
        label: 'Donate',
        href: '/donate',
        key: "donate-key",
        dividerBelow: true
      },
      {
        label: 'Discord',
        href: "https://discord.gg/vWWkVpMjj3",
        key: "discord-key",
        dividerBelow:true
      },
      {
        label: 'System Status',
        href: "https://status.dangercord.com",
        key:"status-key"
      }
    ]
  },
];

export default function NavbarComponent(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ user, setUser ] = useState("loading")
  const { colorMode, toggleColorMode } = useColorMode();
  const router = useRouter()
  const [session, setSession] = useState(null)

  const menulinkcolor = useColorModeValue('gray.600', 'gray.200');

  const MenuLink = ({ children, href, isMenuLink, external }) => (
    !isMenuLink && (
      <Box ml={2} mr={2}>
        <NextLink key={href} href={href || ''} passHref>
          <Link
            isExternal={href.startsWith('http')}
            _hover={{
              textDecoration: 'none',
            }}>
            <MenuItem rounded="md" w="212px" color={menulinkcolor} bg={useColorModeValue('gray.200', "#242424")} _hover={{ bg: useColorModeValue('gray.300', '#353535') }}>
              {children} {(href.startsWith('http') || external) && <ExternalLinkIcon mx={1} />}
            </MenuItem>
          </Link>
        </NextLink>
      </Box>
    )
  )

  const NavLink = ({ children, href, isMenuLink }) => {
    if (!isMenuLink) {
      return (
        <NextLink href={href || ''} passHref>
          <Link
            _hover={{
              textDecoration: 'none'
            }}
          >
            {children}
          </Link>
        </NextLink>
      )
    } else return (
      <>{children}</>
    )
  };

  useEffect(() => {
    fetch("/api/auth/auth").then((res) => res.json()).then((data) => {
      setSession(data?.session || null)
    });

    axios.get('/api/client/@me')
      .then(data => {
        console.log(`[Dangercord] Fetched User Data`)

        if (data.data) {
          setUser(data.data)
        }
      })
  }, [])

  return (
    <>
      <Box 
        px={4}       
        bg={props.homeBg ? useColorModeValue('gray.100', '#1E1E1E') : useColorModeValue('gray.50', '#121212')}
        color={props.homeBg && useColorModeValue('gray.700', 'gray.200')}
      >
        <Container maxW='1250px'>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={'center'}>
              <Box userSelect="none" onClick={() => Router.push("/")} style={{cursor: 'pointer'}}>Dangercord</Box>
              <HStack
                as={'nav'}
                display={{ base: 'none', md: 'flex' }}
              >
                {NAV_ITEMS.map((item) => (
                  <Box key={item.label}>
                    <Menu autoSelect={false}>
                      <NavLink href={item.href} isMenuLink={item.children}>
                        <MenuButton
                          cursor={'pointer'}
                          minW={0}      
                          px={3}
                          py={1}   
                          rounded={'md'}   
                          _hover={{
                            textDecoration: 'none',
                            bg: useColorModeValue('gray.200', props.homeBg ? '#353535' : '#242424')
                          }}>
                            {item.label}
                            {item.children && <ChevronDownIcon ml={0.5} mb={0.5}/>}
                        </MenuButton>
                      </NavLink>

                      {item.children && (
                        <MenuList zIndex={2} bg={useColorModeValue('gray.200', '#242424')}>
                          {item.children.map((child) => (
                            <Box key={child.key} marginTop={1}>
                              <MenuLink href={child.href} external={child.external}>{child.label}</MenuLink>
                              {child.dividerBelow && <MenuDivider ml={3} mr={3}/>}
                            </Box>
                          ))}
                        </MenuList>
                      )}
                    </Menu>
                  </Box>
                ))}

                <Divider orientation="vertical" height="25px"/>

                <Button onClick={toggleColorMode} size="sm" bg={"transparent"}>
                  {colorMode === 'light' ? (
                    <BsMoonFill/>
                  ) : 
                    <BsSun/>
                  }
                </Button>
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              {!props.searchHidden && (
                <Box pr={7}>
                  <Show above='md'>
                    <SearchComponent />
                  </Show>
                </Box>
              )}

              {(session && user !== 'loading') && (
                <NavbarProfileMenu user={user}/>
              )}

              {!session && ( <>
                <Show below='md'>
                  <Avatar 
                    onClick={() => router.push('/api/auth')}
                    size={'sm'}
                    _hover={{ cursor: 'pointer' }}
                    icon={<AiOutlineUser fontSize='1rem'/>}
                  />
                </Show>

                <Show above="md">
                  <Button
                    background={useColorModeValue('gray.300', '#404040')}
                    _hover={{ background: useColorModeValue('gray.400', '#555555') }}
                    onClick={() => router.push('/api/auth')}
                  ><FaDiscord style={{ marginRight: "5px" }}/> Login</Button>
                </Show>
              </>)}
            </Flex>
          </Flex>
        </Container>


        {isOpen && (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {NAV_ITEMS.map((item) => (
                <Box key={item.label} as={item.label}>
                  <Menu>
                    <NavLink href={item.href} isMenuLink={item.children}>
                      <MenuButton
                        cursor={'pointer'}
                        w="full"
                        textAlign="left"
                        minW={0}      
                        px={2}
                        py={'0.3rem'}   
                        rounded={'md'}   
                        _hover={{
                          textDecoration: 'none',
                          bg: useColorModeValue('gray.200', '#121212')
                        }}
                      >
                        {item.label}
                        {item.children && <ChevronDownIcon ml={0.5} mb={0.5}/>}
                      </MenuButton>

                    </NavLink>

                    {item.children && (
                      <MenuList zIndex={2} bg={useColorModeValue('gray.200', '#242424')}>
                        {item.children.map((child) => (
                          <Box key={child.key}>
                            <MenuLink href={child.href} external={child.external}>{child.label}</MenuLink>
                            {child.dividerBelow && <MenuDivider />}
                          </Box>
                        ))}
                      </MenuList>
                    )}
                  </Menu>
                </Box>
              ))}

              <Divider/>

              <Box
                cursor={'pointer'}
                minW={0}      
                px={2}
                py={'0.3rem'}   
                rounded={'md'} 
                bg={'unset'}
                onClick={toggleColorMode}  
                
                _hover={{
                  textDecoration: 'none',
                  bg: useColorModeValue('gray.200', props.homeBg ? 'gray.600' : 'gray.700')
                }}>
                  <Box pos="absolute" mt={1}>
                    {colorMode === 'light' ? <BsMoonFill/> : <BsSun/>}
                  </Box>
                  
                  <Box ml={4}>
                    <Text ml={2}>{colorMode === 'light' ? 'Dark' : 'Light'} mode</Text>
                  </Box>
                </Box>

              {!props.searchHidden && (
                <>
                  <Divider/>
                  <SearchComponent/>
                </>
              )}
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
}