import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  MenuGroup,
  useColorModeValue,
  Badge,
  Box,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

const defaultAvatar = (tag) => {
  if (tag.substr(3,4) === '0' || tag.substr(3,4) === '5') return 'https://cdn.discordapp.com/embed/avatars/0.png'
  if (tag.substr(3,4) === '1' || tag.substr(3,4) === '6') return 'https://cdn.discordapp.com/embed/avatars/1.png'
  if (tag.substr(3,4) === '2' || tag.substr(3,4) === '7') return 'https://cdn.discordapp.com/embed/avatars/2.png'
  if (tag.substr(3,4) === '3' || tag.substr(3,4) === '8') return 'https://cdn.discordapp.com/embed/avatars/3.png'
  if (tag.substr(3,4) === '4' || tag.substr(3,4) === '9') return 'https://cdn.discordapp.com/embed/avatars/5.png'
}

export default function NavbarProfileMenu({ user }) {
  const router = useRouter();

  const stuff = [
    {
      name: 'Profile',
      children: [
        {
          name: 'My Profile',
          href: `/check/${user.id}`
        },
        {
          name: 'My Activity',
          href: '/profile/activity'
        },
      ]
    },
    {
      name: 'API',
      children: [
        {
          name: 'API Keys',
          href: '/profile/api'
        },
        {
          name: 'API Docs',
          href: 'https://docs.dangercord.com'
        },
      ]
    },
    /*
    {
      name: 'Reports',
      children: [
        {
          name: 'Bulk report',
          href: '/bulk_report',
          new: true
        }
      ]
    },*/
    {
      name: 'Admin',
      children: [
        {
          name: 'Administration',
          href: '/admin'
        }
      ]
    }
  ]
  
  /* i fucking hate chakra ui why i need to put color mode value to a fucking variable while i can just put it to color={} */
  const menulinkcolor = useColorModeValue('gray.600', 'gray.400')

  const defaultColor = useColorModeValue('gray.100', '#242424')
  const btnColor = useColorModeValue('gray.300', '#121212')

  return (
    /**
     * You may move the Popover outside Flex.
     */
    <Menu isLazy placement='bottom-end' autoSelect={false}>
      <MenuButton
        as={Button}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        minW={0}>
        <Image 
          src={user?.avatar ? `https://cdn.discordapp.com/avatars/${user?.id}/${user?.avatar}?size=56` : defaultAvatar(user?.discriminator)}
          rounded="full"
          width="35px"
          minWidth="35px"
          alt=""
        />
      </MenuButton>
      <MenuList zIndex={2} bg={defaultColor}>

        {stuff.map((item) => {
          if (item.name.startsWith('Admin') && !user.isAdmin) return;

          return (
            <>
              {item.name && (
                <MenuGroup title={item.name} ml={5}>
                  {item.children.map((link) => (
                    <Box ml={2} mr={2} key={link}>
                      <NextLink href={link.href || ''} passHref>
                        <Link
                          isExternal={link.href.startsWith('http')}
                          _hover={{
                            textDecoration: 'none',
                          }}>
                          <MenuItem 
                            rounded="md" 
                        
                            w="212px" 
                            color={menulinkcolor}
                            bg={"transparent"}
                            _hover={{
                              bg: btnColor
                            }}
                          >
                            {link.name} 
                            {link.href.startsWith('http') && <ExternalLinkIcon mx={1} />} 
                            {link.new && <Badge colorScheme='green' ml={2}>New</Badge>}
                          </MenuItem>
                        </Link>
                      </NextLink>
                    </Box>
                  ))}
                </MenuGroup>
              )}
            </>
          )
        })}

        <MenuDivider ml={4} mr={4}/>

        <MenuItem 
          onClick={() => router.push('/api/auth/logout')}
          rounded="md" 
          ml={2} 
          mr={2} 
          color="red.400"
          bg={"transparent"}
          _hover={{
            bg: btnColor
          }}
          w="212px">
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}